import { NavLink } from "react-router-dom";
import { Container } from "react-bootstrap";
// import { useLocation } from "react-router-dom";
// import ReactGA from 'react-ga4';

function Footer() {

  return (
    <footer className='text-white text-center p-3'>
      <Container>
        <div>
          <NavLink className="text-white text-decoration-none px-1 link-footer" to={"/accueil"}>Accueil</NavLink>
          <NavLink className="text-white text-decoration-none px-1 link-footer" to={"/forfaits"}>Forfaits</NavLink>
          <NavLink className="text-white text-decoration-none px-1 link-footer" to={"/hotesses"}>Hôtesses en visio</NavLink>
        </div>
        <div>
          <span>Sites partenaires :
            <br />
            <a className="external-link" href="https://rosetelephone.fr" target="_blank" rel="noreferrer">rosetelephone.fr</a>
            <br />
            <a className="external-link" href="https://maitresses-telephone-rose.com/" target="_blank" rel="noreferrer">maitresses-telephone-rose.com</a>
          </span>
        </div>
        <div>
          <small><NavLink className="text-white pe-1" to={"/mentions-legales"} caseSensitive end>Mentions légales</NavLink></small>
          <small><NavLink className="text-white ps-1" to={"/cgv"} caseSensitive end>CGV</NavLink></small>
        </div>
        <small className="text-white">Copyright &copy; 2024 visio-telrose.fr. Tous droits réservés.</small>
      </Container>
    </footer>
  )
}

export default Footer;