import { Container, Row, Col, Image, Button, Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useEffect } from "react";
import headerPackagesVisio from '../img/header-forfaits-visio.webp';
import packageVisio1 from '../img/carte-forfait-1.webp';
import packageVisio2 from '../img/carte-forfait-2.webp';
import packageVisio3 from '../img/carte-forfait-3.webp';
import packageVisio4 from '../img/carte-forfait-4.webp';

function Packages() {
  useEffect(() => {
    setTimeout(() => {
      document.getElementById("iframe-container").style.height = "825px";
      document.getElementById("iframe-chat").style.position = "static";
      document.getElementById("iframe-chat").style.opacity = "100";
      document.getElementById("button-loading").style.display = "none";
      document.getElementById("title-chat").style.display = "block";
    }, 4000);
  }, [])

  return (
    <>
      <Helmet>
        <title>Site de Téléphone Rose en Visio avec Forfaits Pas Cher</title>
        <meta name="description"
          content="Découvrez nos forfaits d'appels visio avec des hôtesses charmantes. Profitez d'une expérience interactive et sensuelle à tout moment. Appelez maintenant !" />
        <link rel="canonical" href="https://visio-telrose.fr/forfaits" />
        {/* <link rel="preload" as="image" href="https://telrose-telephone.be/img/banner-home.webp" /> */}
      </Helmet>
      <section className="header pt-3 mb-4">
        <Container fluid='lg'>
          <Row>
            <Col xs={12} lg={6} className="d-flex flex-column justify-content-center">
              <div className='title-header-page'>
                <h1 className="mb-4 text-center text-lg-start fw-bold display-5">Forfaits Téléphone Rose en Visio : Expériences Intimes en Direct </h1>
                <h2 className="fs-4 text-center text-lg-start fw-normal">Découvrez nos différentes gammes de forfaits pour des appels en visio</h2>
              </div>
            </Col>
            <Col xs={12} lg={6}>
              <div className='image-header-page text-center'>
                <Image width={660} height={440} src={headerPackagesVisio} fluid alt="forfaits visio" />
              </div>
            </Col>
          </Row>
        </Container >
      </section >
      <Container fluid="lg" as={"section"} className="overflow-hidden">
        <div className="title p-2 text-center mb-4">
          <h3 className="fw-bold">Vous souhaitez utiliser l'un de nos forfaits pour des appels visio avec l'une de nos hôtesses ?</h3>
          <h4 className="fw-bold">Rien de plus simple, on vous explique comment faire.</h4>
        </div>
        <div className="mb-4">
          <p>1. Pour commencer, veuillez vous créer un compte en cliquant sur le lien suivant : <a className="external-link" href="https://app.dialotel.io/register/167-sexy-love-visio" target="_blank"><strong>Inscription</strong></a>.</p>
          <p>2. Une fois inscrit, cliquez sur le forfait qui vous intéresse plus bas sur cette page, puis connectez vous à votre compte, vous serez automatiquement dirigé vers votre espace sur la page <strong>"Créditer mon compte"</strong> avec le forfait initialement sélectionné.</p>
          <p>3. Si vous le souhaitez, vous pouvez modifier le forfait sélectionné depuis la section <strong>"je choisi mon forfait privé"</strong>.</p>
          <p>4. Une fois votre compte crédité, vous pouvez lancer des appels au forfait. Pour se faire, il vous suffit de cliquer sur <strong>"Je consulte"</strong> dans le menu latéral depuis votre espace, puis choisissez parmi l'une de nos hôtesses en cliquant sur son profil, une fois sur sa page de profil, cliquez sur <strong>"Lancer l'appel au forfait"</strong>.</p>
          <p>5. Une fois en ligne avec l'hôtesse, vous pourrez utiliser notre chat-visio plus bas sur cette page pour échanger en direct en visio</p>
        </div>
        <div className="title p-2 text-center mb-4">
          <h5 className="fs-3 fw-bold">Les Forfaits</h5>
        </div>
        <Row>
          <Col xs={6} sm={6} md={4} lg={3} className="text-center mb-4"><a target="_blank" href="https://app.dialotel.io/login/167-sexy-love-visio/0-forfait/7-forfait-prive/719-forfait-visio-decouverte"><Image width={344} height={415} className="card-package" src={packageVisio1} rounded fluid alt="forfait visio découverte : 20 minutes à 18 euros" /></a></Col>
          <Col xs={6} sm={6} md={4} lg={3} className="text-center mb-4"><a target="_blank" href="https://app.dialotel.io/login/167-sexy-love-visio/0-forfait/7-forfait-prive/720-forfait-visio-standard"><Image width={344} height={415} className="card-package" src={packageVisio2} rounded fluid alt="forfait visio standard 30 minutes à 25 euros" /></a></Col>
          <Col xs={6} sm={6} md={4} lg={3} className="text-center mb-4"><a target="_blank" href="https://app.dialotel.io/login/167-sexy-love-visio/0-forfait/7-forfait-prive/724-forfait-visio-premium"><Image width={344} height={415} className="card-package" src={packageVisio3} rounded fluid alt="forfait visio premium 60 minutes à 45 euros" /></a></Col>
          <Col xs={6} sm={6} md={4} lg={3} className="text-center mb-4"><a target="_blank" href="https://app.dialotel.io/login/167-sexy-love-visio/0-forfait/7-forfait-prive/1242-forfait-visio-vip"><Image width={344} height={415} className="card-package" src={packageVisio4} rounded fluid alt="forfait visio vip 90 minutes à 65 euros" /></a></Col>
        </Row>
        <Container className="my-3 text-center" fluid="lg">
          <Button id='button-loading' style={{ display: "block" }} className='loading-chat fw-bold mt-3' disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className='me-2'
            />
            Chargement du chat
          </Button>
          <h5 id="title-chat" className='mb-2 p-3 fs-4 fw-bold rounded-1'>CHAT-VISIO</h5>
          <div id='iframe-container'>
            <iframe title="chat-visio" id="iframe-chat" src="https://html5-chat.com/chat/50573/66d58b670ca26" allow="camera; fullscreen" width="100%" height="100%"></iframe>
          </div>
        </Container>
      </Container>
    </>
  )
}

export default Packages;