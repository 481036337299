import headerHomeVisio from '../img/header-home-visio.webp';
import { Container, Row, Col, Image } from "react-bootstrap";
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import lienMaitressesDominatrices from '../img/Lienmaitressesdominatrices.webp'
import lienRoseTelephone from '../img/Lienrosetelephone12.webp'

function Home() {
  return (
    <>
      <Helmet>
        <title>Télrose en visio sans attente | Appelez le 0895680800</title>
        <meta name="description"
          content="Téléphone rose en visio avec des forfaits adaptés à vos besoins. Profitez d'un service de conversation intime et personnalisée via webcam, disponible 24h/24, pour des moments uniques et inoubliables." />
        <link rel="canonical" href="https://visio-telrose.fr" />
        {/* <link rel="preload" as="image" href="https://telrose-telephone.be/img/banner-home.webp" /> */}
      </Helmet>
      <section className="header pt-3 mb-4">
        <Container fluid='lg'>
          <Row>
            <Col xs={12} lg={6} className="d-flex flex-column justify-content-center">
              <div className='title-header-page'>
                <h1 className="mb-4 text-center text-lg-start fw-bold display-5">Service de Téléphone Rose en Visio  Rapide et Discret </h1>
                <h2 className="fs-4 text-center text-lg-start fw-normal">Découvrez des échanges vidéo privés et excitants avec nos hôtesses en toute discrétion.</h2>
              </div>
            </Col>
            <Col xs={12} lg={6}>
              <div className='image-header-page text-center'>
                <Image width={660} height={440} src={headerHomeVisio} fluid alt='écrans visio' />
              </div>
            </Col>
          </Row>
        </Container >
      </section >
      <Container fluid='lg' as={"section"} className='mb-4 overflow-hidden'>
        <Row>
          <Col xs={12} md={6} className='mb-4 mb-md-0'>
            <article className="d-flex flex-column h-100 p-3 text-center article-home rounded">
              <h3 className='mb-4 fw-bold'>Découvrez nos Forfaits Exclusifs pour des Appels Visio</h3>
              <p className='mb-4'>Plongez dans une expérience unique avec nos forfaits spécialement conçus pour des appels visio avec l'une de nos hôtesses. Que vous cherchiez à pimenter vos conversations ou à vivre des moments intenses et captivants, nous avons le forfait qu'il vous faut !</p>
              <NavLink to={'forfaits'} className="mt-auto mx-auto article-link p-2 rounded-5">Les forfaits</NavLink>
            </article>
          </Col>
          <Col xs={12} md={6}>
            <article className="d-flex flex-column h-100 p-3 text-center article-home rounded">
              <h3 className='mb-4 fw-bold'>Découvrez Nos Hôtesses en Visio</h3>
              <p className='mb-4'>Vous êtes sur le point de découvrir un espace où vos fantasmes prennent vie et où chaque appel est une expérience unique. Nos hôtesses sont là pour vous offrir des moments intimes et inoubliables à travers des appels visio personnalisés.</p>
              <NavLink to={'hotesses'} className="mt-auto mx-auto article-link p-2 rounded-5">Nos hôtesses en visio</NavLink>
            </article>
          </Col>
        </Row>
      </Container>
      <Container as={"section"} fluid="lg" className="my-4 my-md-5">
        <h2 className="text-center mt-3 mb-5 mt-lg-5 py-3 py-md-4 title fw-bold">Liens sites web tél rose pas cher francais</h2>
        <article className="mb-5">
          <Row>
            <Col xs={12} md={6} className="order-2 order-md-1 px-3 px-md-0 text-center">
              <a href="https://rosetelephone.fr" target="_blank" rel="noreferrer">
                <Image width={660} height={360} src={lienRoseTelephone} fluid alt="Télrose pas cher" />
              </a>
            </Col>
            <Col md={6} className="d-flex flex-column justify-content-center text-center text-md-start order-1 order-md-2">
              <h2 className="fw-bold mb-4 display-5">Télrose pas cher</h2>
              <p className="fw-normal">Le <strong>téléphone rose</strong>, c’est une porte ouverte sur un monde où seul le désir parle. Depuis des années, il reste un incontournable pour ceux qui aiment les plaisirs sensuels et les échanges brûlants.</p>
              <p><srong>Une voix suave, des mots choisis, une tension qui monte…</srong>  et toi, prêt à succomber ?</p>
              <p>📞 <strong>Un instant intime, juste pour toi</strong></p>
              <p>📞 <strong>Laisse ton imagination faire le reste…</strong></p>
              <p><strong>Compose et laisse-toi envoûter.</strong> 🔥</p>
            </Col>
            <Col md={{ span: 4, offset: 7 }} className="order-3 text-center">
              <a className="d-block mt-5" href="https://rosetelephone.fr" target="_blank" rel="noreferrer">
                <button className="button-home">Accéder au site</button>
              </a>
            </Col>
          </Row>
        </article>
        <hr />
        <article className="mt-5 mb-5">
          <Row>
            <Col className="d-flex flex-column mb-2 justify-content-center text-center text-md-start">
              <h2 className="fw-bold mb-4 display-5">Maîtresses dominatrices tél rose</h2>
              <p className="fw-normal"><strong>Et si tu laissais une vraie dominatrice prendre le contrôle ?</strong></p>
              <p>Tu aimes être dominé, humilié ou dressé selon ses désirs ? Ce numéro SM est fait pour toi. Ici, <strong>c’est elle qui commande…</strong> et toi, tu exécutes. Chaque ordre, chaque supplice sera un pas de plus vers le plaisir absolu.</p>
              <p>🔗 <strong> Maîtresses expertes et sans tabous</strong></p>
              <p>🔗 <strong>Un jeu de pouvoir aussi intense qu’excitant</strong></p>
              <p>🔗 <strong>Service confidentiel et ultra excitant</strong></p>
              <p>Es-tu prêt à te soumettre ? 📞😏</p>
            </Col>
            <Col xs={12} md={6} className="text-center px-3 px-md-0 text-center">
              <a href="https://maitresses-telephone-rose.com" target="_blank" rel="noreferrer">
                <Image width={660} height={360} src={lienMaitressesDominatrices} fluid alt="Maitresse dominatrice téléphone rose" />
              </a>
            </Col>
            <Col md={{ span: 2, offset: 2 }} className="order-3 text-center">
              <a className="d-block mt-5" href="https://maitresses-telephone-rose.com" target="_blank" rel="noreferrer">
                <button className="button-home">Accéder au site</button>
              </a>
            </Col>
          </Row>
        </article>
      </Container>
    </>
  )
}

export default Home;